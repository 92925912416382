import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import RichMediaHightlightCard from "../../../../V2/Cards/RichMedia/Highlight"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokRichMediaHightlightCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.RichMediaHightlightCard>) => {
  return (
    <RichMediaHightlightCard
      backgroundVideo={
        blok.backgroundVideoUrl && linkIsNotNull(blok.backgroundVideoUrl)
          ? getUrlFromStoryblokLink(blok.backgroundVideoUrl)
          : ""
      }
      backgroundImage={blok.backgroundImage.filename}
      backgroundImageAlt={blok.backgroundImage.alt}
      icon={blok.icon?.filename}
      iconAlt={blok.icon?.alt}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokRichMediaHightlightCard
